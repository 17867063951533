import React from 'react';
import { EuiButton, EuiImage, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiSpacer } from '@elastic/eui';
import { useStore } from '../stores';
import { observer } from 'mobx-react-lite';
import Images from '../images';
import { useLocation } from "@reach/router";
import { navigate } from 'gatsby-link';
import '../styles/hailcx-modal.css';

const HailCXRepairTypeAlert = () => {
	const { customerStore } = useStore();
	const { showRepairTypeChangedAlert, setShowRepairTypeChangedAlert } = customerStore;
	const location = useLocation();

	const readyToBookRoute = '/book/ready-to-book';

	const closeAlert = () => {
		setShowRepairTypeChangedAlert(false);

		if (location && location.pathname !== readyToBookRoute) {
			console.log('Navigating...');
			navigate(readyToBookRoute);
		}
	};

	return (
		<>
			{(showRepairTypeChangedAlert &&
				<EuiModal onClose={closeAlert}>
					<EuiModalHeader>
						<EuiModalHeaderTitle>
							<h1>Hang on</h1>
							<EuiImage src={Images.CarTilt} alt="Car Tilt"></EuiImage>
						</EuiModalHeaderTitle>
					</EuiModalHeader>

					<EuiModalBody>
						<p><strong>We’ve had to change your method of repair.</strong></p>
						<EuiSpacer />
						<p>The technician will be with you shortly to answer any questions.</p>
						<EuiSpacer />
						<p>Please choose a new booking time.</p>
					</EuiModalBody>

					<EuiModalFooter>
						<EuiButton onClick={closeAlert} fill>
							Close
						</EuiButton>
					</EuiModalFooter>
				</EuiModal>
			)}
		</>
	);
}

export default observer(HailCXRepairTypeAlert);