import React, { Fragment, useEffect, useState } from "react"
import HailCXLayout from "../../components/layout/hailcx-layout"
import HailCXRightImageHeader from "../../components/layout/header/hailcx-right-image-header"
import HailCXDoubleButtonFooter from "../../components/layout/footer/hailcx-double-button-footer"
import {
  EuiDescriptionList,
  EuiImage,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from "@elastic/eui"
import Images from "../../images"
import { navigate } from "gatsby-link"
import { observer } from "mobx-react-lite"
import { useStore } from "../../stores"
import { RepairTypes } from "../../entities/constants"
import SummaryItem from "../../entities/summaryItems"
import "../../styles/hailcx-description-lists.css"
import {
  getBookingDateTimeUIFormatted,
  getCompletionDateUIFormatted,
  saveEntryPointUrl,
} from "../../entities/utilities"
import HailCXRepairTypeAlert from "../../components/hailcx-repairtype-alert"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import SummaryRepairLocation from "../../components/booking/summary-repairlocation"

const ManageBoookingPage = () => {
  const [bookingSummary, setBookingSummary] = useState<SummaryItem[]>([])
  const { customerStore, facilityStore, bookingStore } = useStore()
  const { facility } = facilityStore
  const { currentBooking } = bookingStore
  const location = useLocation()
  const queriedString = queryString.parse(location.search)
  const { bookingNumber, eqn } = queriedString

  const data = {
    title: "Manage",
    primaryContent: "Reschedule my booking",
    secondaryContent: "Cancel my booking",
  }

  const handlePrimaryClick = () => {
    console.log("Reschedule booking.")

    navigate("/book/reschedule-booking")
  }

  const handleSecondaryClick = () => {
    console.log("Cancel booking.")

    navigate("/book/cancel")
  }

  useEffect(() => {
    //as this page is the entry point of reschedule booking process, save url as entry point url
    saveEntryPointUrl()
  }, [])

  useEffect(() => {
    if (bookingNumber) {
      bookingStore.setBookingNumber(bookingNumber as string)
    }

    if (eqn) {
      customerStore.setCustomerEncryptedQuoteNumber(eqn as string)
    }

    if (
      !currentBooking ||
      (currentBooking &&
        bookingNumber &&
        currentBooking.bookingId != bookingNumber)
    ) {
      bookingStore.loadCurrentBooking()
    }

    if (!customerStore.customer) {
      customerStore.loadCustomer().then(() => {
        facilityStore.loadFacilityDetails(
          customerStore.customer?.registeredAtFacilityId
        )
      })
    }

    if (currentBooking) {
      const repairType = RepairTypes.find(
        rt => rt.acronym === currentBooking?.repairType
      )

      if (repairType) {
        const summary = [
          {
            title: <EuiImage src={Images.CarRepair} alt="Repair Type" />,
            description: (
              <EuiText grow={false}>
                Repair required
                <br />
                <strong>{repairType.name}</strong>
                <br />
                <p className="highlightedText">
                  {repairType.durationDescription}
                </p>
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.Clock} alt="Drop Off" />,
            description: (
              <EuiText grow={false}>
                <p>
                  <strong>Drop off</strong>
                  <br />
                  {`${getBookingDateTimeUIFormatted(
                    currentBooking?.repairDate || "",
                    currentBooking?.dropOffTime || ""
                  )}`}
                </p>
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.CalendarIcon} alt="Completion" />,
            description: (
              <EuiText grow={false}>
                <p>
                  <strong>Estimated completion</strong>
                  <br />
                  {getCompletionDateUIFormatted(
                    currentBooking?.repairDate || "",
                    repairType.durationInWeeks
                  )}
                </p>
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.MapMarker} alt="Location" />,
            description: (
              <EuiText grow={false}>
                <SummaryRepairLocation facility={facility} />
              </EuiText>
            ),
          },
        ]

        setBookingSummary([...summary])
      }
    }
  }, [location, currentBooking, facilityStore.facility])

  return (
    <HailCXLayout
      header={
        <HailCXRightImageHeader
          title={data.title}
          progressValue={0}
          vehicleInfo={customerStore.getVehicleInfo()}
        />
      }
      footer={
        <HailCXDoubleButtonFooter
          primaryContent={data.primaryContent}
          secondaryContent={data.secondaryContent}
          handlePrimaryClick={handlePrimaryClick}
          handleSecondaryClick={handleSecondaryClick}
          primaryButtonColor="primary"
        />
      }
    >
      <EuiText
        grow={false}
        className="eui-textLeft"
        style={{ marginRight: "auto", marginLeft: "auto" }}
      >
        <h1>Your booking</h1>
        <p>
          {customerStore.customer?.firstName}, your current booking is as
          follows:
        </p>
      </EuiText>
      <EuiSpacer />
      <EuiPanel>
        <EuiDescriptionList type="column" listItems={bookingSummary} />
      </EuiPanel>
      <HailCXRepairTypeAlert />
    </HailCXLayout>
  )
}

export default observer(ManageBoookingPage)
