import React, { Fragment } from 'react';
import { Facility } from '../../models';

interface Props {
    facility?: Facility | undefined;
}

const SummaryRepairLocation = (props: Props) => {

    const { facility } = props;

    const repairLocationAddress: string[] = facility ? [
        facility.streetAddressLine1,
        facility.streetAddressLine2,
        facility.suburb,
        facility.state
    ].filter((item) => item) : [];

    return (
        <p>
            <strong>Repair location</strong>
            {repairLocationAddress.map((item, index) =>
                <Fragment key={index}><br />{item}</Fragment>
            )}
        </p>
    )
}

export default SummaryRepairLocation;